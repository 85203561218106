import Container from "../../components/Container";
import Icon from "../../components/Icon";
import PageTitle from "../../components/Text/PageTitle";

const ServicesPage = () => {
  return (
    <>
      <img className="mx-auto w-full h-96 mt-0 mb-8 md:mb-10 lg:mb-12 object-cover object-center" src="/nightCityLowRes.jpg" alt="ciudad noche"/>
      <PageTitle title="Servicios"/>
      <Container borderTop paddingTop paddingBottom marginBottom marginTop>
        <div className="flex flex-row" id="service1">
          <div className="flex flex-col h-full my-auto justify-center">
            <Icon svgSrc="/icono2.svg" imgSrc="/icono2.png" className="my-auto"/>
          </div>
          <div className="flex flex-1 flex-col ml-2 md:ml-4 my-auto pr-4">
            <p className="uppercase text-lg md:text-xl lg:text-2xl mb-2 md:mb-4 lg:mb-6 text-gray-500">Política</p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              Identificamos anticipadamente los <span className="text-teal-600 font-semibold">riesgos y oportunidades</span> que las políticas públicas y la acción del gobierno pueden tener para las empresas.
            </p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              Damos cobertura a las actividades legislativas,<span className="text-teal-600 font-semibold"> sintetizando con informes de inteligencia la información de impacto para el cliente.</span> Ejecutamos acciones de cabildeo con legisladores.
            </p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              Monitoreamos la información de medios que afectan a las empresas y<span className="text-teal-600 font-semibold"> elaboramos estrategias de comunicación política.</span>
            </p>
          </div>
        </div>
      </Container>
      <Container borderTop paddingTop paddingBottom marginBottom marginTop>
        <div className="flex flex-row-reverse"  id="service2">
          <div className="flex flex-col h-full my-auto justify-center">
            <Icon svgSrc="/archivo_1.svg" imgSrc="/archivo_1.png" className="my-auto"/>
          </div>
          <div className="flex flex-1 flex-col mr-2 md:mr-4 my-auto pl-6">
            <p className="uppercase text-lg md:text-xl lg:text-2xl mb-2 md:mb-4 lg:mb-6 text-gray-500">Financiero</p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              <span className="text-teal-600 font-semibold">Asesoramos a nuestros clientes sobre el marco legal</span> aplicable a los servicios financieros digitales y nuevos modelos financieros, a efecto de obtener las licencias para operar en el país y en el cumplimiento de los reportes establecidos por ley.
            </p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              <span className="text-teal-600 font-semibold">Ayudamos</span> a nuestros clientes en el cumplimiento normativo frente a autoridades financieras (Condusef, CNBV, Banxico)
            </p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              <span className="text-teal-600 font-semibold">Asesoramos</span> a nuestros clientes en auditoria en materia de prevención de Operaciones con Recursos de Procedencia ilícita.
            </p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              <span className="text-teal-600 font-semibold">De la mano de nuestros clientes, verificamos</span> el cumplimiento normativo respecto de Actividades no financieras (LFPIORPI, Actividades Vulnerables y Profeco).
            </p>
          </div>
        </div>
      </Container>
      <Container borderTop paddingTop paddingBottom marginBottom marginTop>
        <div className="flex flex-row"  id="service3">
          <div className="flex flex-col h-full my-auto justify-center">
            <Icon svgSrc="/icono3.svg" imgSrc="/icono3.png" className="my-auto"/>
          </div>
          <div className="flex flex-1 flex-col ml-2 md:ml-4 my-auto pr-4">
            <p className="uppercase text-lg md:text-xl lg:text-2xl mb-2 md:mb-4 lg:mb-6 text-gray-500">Energía</p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              <span className="text-teal-600 font-semibold">Generamos soluciones</span> eficientes para los procesos y actividades de la cadena de suministro y logística de combustibles e hidrocarburos.
            </p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              <span className="text-teal-600 font-semibold">Contribuimos a optimizar,</span> revisar y mantenerte al tanto los procesos que los negocios deben cumplir con las autoridades en materia energética (ASEA, CRE, Sener).
            </p>
          </div>
        </div>
      </Container>
      <Container borderTop paddingTop paddingBottom marginBottom marginTop>
        <div className="flex flex-row-reverse"  id="service4">
          <div className="flex flex-col h-full my-auto justify-center">
            <Icon svgSrc="/icono4.svg" imgSrc="/icono4.png" className="my-auto"/>
          </div>
          <div className="flex flex-1 flex-col mr-2 md:mr-4 my-auto pl-6">
            <p className="uppercase text-lg md:text-xl lg:text-2xl mb-2 md:mb-4 lg:mb-6 text-gray-500">COFEPRIS</p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              <span className="text-teal-600 font-semibold">Asesoramos a empresas nacionales y extranjeras</span> en sus trámites ante la COFEPRIS, con el fin de que puedan comercializar sus productos en México.
            </p>
          </div>
        </div>
      </Container>
      <Container borderTop paddingTop paddingBottom marginBottom marginTop>
        <div className="flex flex-row"  id="service5">
          <div className="flex flex-col h-full my-auto justify-center">
            <Icon svgSrc="/contrato.svg" imgSrc="/contrato.png" className="my-auto"/>
          </div>
          <div className="flex flex-1 flex-col ml-2 md:ml-4 my-auto pr-4">
            <p className="uppercase text-lg md:text-xl lg:text-2xl mb-2 md:mb-4 lg:mb-6 text-gray-500">Contrataciones Públicas</p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              Contamos con conocimiento de primera mano y especializado en relación con <span className="text-teal-600 font-semibold">contrataciones públicas y licitaciones.</span>
            </p>
          </div>
        </div>
      </Container>
      <Container borderTop paddingTop paddingBottom marginBottom marginTop>
        <div className="flex flex-row"  id="service6">
          <div className="flex flex-col h-full my-auto justify-center">
            <Icon svgSrc="/manos.svg" imgSrc="/manos.png" className="my-auto"/>
          </div>
          <div className="flex flex-1 flex-col ml-2 md:ml-4 my-auto pr-4">
            <p className="uppercase text-lg md:text-xl lg:text-2xl mb-2 md:mb-4 lg:mb-6 text-gray-500">En tú Negocio</p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              <span className="text-teal-600 font-semibold">Proporcionamos consultoría en Materia de Derecho Corporativo,</span> coadyuvamos en la constitución, escisión y la transformación de cualquier tipo de sociedades; y en el seguimiento en la vida corporativa de la sociedad (actas de asambleas, sesiones de consejo y mantenimiento de libros corporativos).
            </p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              <span className="text-teal-600 font-semibold">Aconsejamos</span> respecto a las mejores soluciones corporativas y comerciales relacionadas con las operaciones que nuestros clientes deseen llevar a cabo en el país.
            </p>
          </div>
        </div>
      </Container>
      <Container borderTop paddingTop paddingBottom marginBottom marginTop>
        <div className="flex flex-row"  id="service7">
          <div className="flex flex-col h-full my-auto justify-center">
            <Icon svgSrc="/ircono.svg" imgSrc="/ircono.png" className="my-auto"/>
          </div>
          <div className="flex flex-1 flex-col ml-2 md:ml-4 my-auto pr-4">
            <p className="uppercase text-lg md:text-xl lg:text-2xl mb-2 md:mb-4 lg:mb-6 text-gray-500">Fiscal</p>
            <p className="text-gray-500 text-sm md:text-base lg:text-lg">
              <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
              <span className="text-teal-600 font-semibold">Proporcionamos certidumbre jurídica</span> a nuestros clientes mediante el diseño e implementación de estrategias para el estricto cumplimiento de las obligaciones fiscales, laborales, legales y de seguridad social.
            </p>
          </div>
        </div>
      </Container>
    </>
  );
}
<script id="pixel-chaty" async="true" src="https://cdn.chaty.app/pixel.js?id=qbvcAgAw"></script>
export default ServicesPage;