import { useState } from "react";
import PageTitle from "../../components/Text/PageTitle";
import Container from "../../components/Container";
import Icon from "../../components/Icon";

const HomePage = () => {

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  })

  const handleInputChange = (event) => {
    const target = event.target;
    const value =  target.value;
    const name = target.name;
    setFormValues(state => ({
      ...state,
      [name]: value
    }));
  }

  const onSubmit = (event) =>{
    event.preventDefault();
    const { name, email, message } = formValues

    var link = "mailto:"+"jimena@inteligenciamas.com"
            + "&subject=" + encodeURIComponent("Solicito información desde tu sitio Web")
            + "&body=" + encodeURIComponent(
              "Hola, mi nombre es: " + name 
              + "\nY mi correo es: " + email 
              + "\nMi solicitud de información es: \n" + message
            )
    ;
    window.location.href = link;
  }

  return (
    <>
      <img 
        className="mx-auto w-full h-96 mt-0 object-cover" 
        src="/palacioNacional-2.jpg" 
        alt="palacio nacional"
        style={{objectPosition:"45% 27%"}}
      />
      <Container paddingTop>
        <PageTitle title="+ Información + Resultados"/>
        <div className="md:flex md:flex-row mt-6 md:mt-8 lg:mt-10 mx-auto justify-center">
          <div className="mx-auto md:mx-0 md:flex mb-6">
            <img 
              className="w-80 h-80 md:w-96 md:h-96 lg:w-102 lg:h-102 mx-auto my-auto rounded shadow object-cover mx-auto" 
              src="/calleNoche-1.jpg" 
              alt="estatua pegaso"
              style={{objectPosition:"79% 50%"}}  
            />
          </div>
          <div className="md:flex md:flex-col justify-center text-gray-500">
            <div className="mx-auto w-96 px-2 md:px-4 lg:px-8">
              <p className="uppercase text-2xl md:text-3xl lg:text-4xl mb-4">te ayudamos a</p>
              <p className="mb-4 md:mb-6 lg:mb-8 text-sm md:text-base">anticipar los cambios, promoverlos y ser tomador de oportunidades:</p>
              <p className="mb-2 md:mb-4 lg:mb-6 text-sm md:text-base"><span className="uppercase text-base md:text-lg lg:text-xl font-semibold text-teal-600">Accede </span>a información útil y oportuna sobre los asuntos públicos.</p>
              <p className="mb-2 md:mb-4 lg:mb-6 text-sm md:text-base"><span className="uppercase text-base md:text-lg lg:text-xl font-semibold text-teal-600">Avanza </span>mediante el diálogo y la negociación.</p>
            </div>
          </div>
        </div>
      </Container>
      <Container marginTop marginBottom paddingBottom paddingTop borderBottom borderTop>
        <PageTitle title="Servicios"/>
        <div className="w-8/12 md:w-full mx-auto flex flex-wrap flex-row md:justify-between mt-8 md:mt-10 lg:mt-12">
          <Icon 
            imgSrc="/icono2.png" 
            svgSrc="/icono2.svg" 
            title="Política"
            selectable
            target="/services#service1"
          />
          <Icon 
            imgSrc="/archivo_1.png" 
            svgSrc="/archivo_1.svg" 
            title="Financiero"
            selectable
            target="/services#service2"
          />
          <Icon 
            imgSrc="/icono3.png" 
            svgSrc="/icono3.svg" 
            title="Energía"
            selectable
            target="/services#service3"
          />
          <Icon 
            imgSrc="/icono4.png" 
            svgSrc="/icono4.svg" 
            title="COFEPRIS"
            selectable
            target="/services#service4"
          />
          <Icon 
            imgSrc="/contrato.png" 
            svgSrc="/contrato.svg" 
            title="Contrataciones Públicas"
            selectable
            target="/services#service5"
          />
          <Icon 
            imgSrc="/manos.png" 
            svgSrc="/manos.svg" 
            title="En tú Negocio"
            selectable
            target="/services#service6"
          />
          <Icon 
            imgSrc="/ircono.png" 
            svgSrc="/ircono.svg" 
            title="Fiscal"
            selectable
            target="/services#service7"
          />
        </div>
      </Container>
      <Container>
        <PageTitle title="Te escuchamos"/>
        <form className="mt-8 md:mt-10 lg:mt-12" onSubmit={onSubmit} encType="multipart/form-data">
          <div className="flex flex-col md:flex-row px-4 md:px-0">
            <div className="flex flex-1 flex-col md:flex-row mb-4">
              <label htmlFor="name" className="text-gray-500 text-sm md:text-base">Nombre:</label>
              <input name="name" onChange={handleInputChange} className="flex flex-1 md:ml-2 shadow-md border-teal-600 border-2"></input>
            </div>
            <div className="flex flex-1 flex-col md:flex-row md:ml-4 md:pl-4 mb-4">
              <label htmlFor="email" className="text-gray-500 text-sm md:text-base">Correo:</label>
              <input name="email" onChange={handleInputChange} className="flex flex-1 md:ml-2 shadow-md border-teal-600 border-2"></input>
            </div>
          </div>
          <div className="flex flex-1 flex-col md:flex-row w-full px-4 md:px-0 md:mt-4">
            <label htmlFor="message" className="text-gray-500 text-sm md:text-base">Mensaje:</label>
            <textarea 
              onChange={handleInputChange}
              name="message" 
              className="flex flex-1 md:ml-2 shadow-md border-teal-600 border-2 h-auto"
              cols="20"
              rows="4"
            >
            </textarea>
          </div>
          <div className="w-full flex flex-row-reverse mt-4 px-4 md:px-0">
            <button 
              className="flex rounded-md text-white bg-teal-600 hover:bg-blue-500 hover:text-gray-100 uppercase px-6 py-2 ml-auto mr-0 text-xs md:text-sm"
            >
              enviar
            </button>
          </div>
        </form>
      </Container>
      <script id="pixel-chaty" async="true" src="https://cdn.chaty.app/pixel.js?id=qbvcAgAw"></script>
    </>
    
  );
}

export default HomePage;