import { Route, Switch } from "react-router-dom";
import AboutPage from "./about";
import HomePage from "./home";
import NewsPage from "./news";
import ServicesPage from "./services";

const PageWrapper = () => {
	return (
    <Switch>
		<Route exact path="/" component={HomePage}/>
		<Route exact path="/about" component={AboutPage}/>
		<Route exact path="/services" component={ServicesPage}/>
		<Route exact path="/news" component={NewsPage}/>
	</Switch>
  );
}
export default PageWrapper;