import Container from "../../components/Container";
import TeamRow from "../../components/TeamRow";
import PageTitle from "../../components/Text/PageTitle";

const AboutPage = () => {
  return (
    <>
      <img className="mx-auto w-full h-96 mt-0 object-cover" src="/cityLowRes.jpg" alt="ciudad"/>
      <Container paddingTop paddingBottom paddingLeft paddingRight>
        <PageTitle title="¿Quiénes Somos?"/>
        <p className="text-base md:text-lg lg:text-xl text-gray-500 mx-auto mb-1 md:mb-2 lg:mb-4 mt-8 md:mt-10 lg:mt-12">
          <span className="font-bold text-teal-600 text-lg md:text-xl lg:text-2xl">+ </span> 
          Firma especializada en asuntos gubernamentales y regulatorios con más de quince años de experiencia representando los intereses de nuestros clientes con legisladores y autoridades.
        </p>
        <p className="text-base md:text-lg lg:text-xl text-gray-500 mx-auto mb-1 md:mb-2 lg:mb-4">
          <span className="font-bold text-teal-600 text-lg md:text-xl lg:text-2xl">+ </span> 
          Contamos con exfuncionarios con experiencia legal y amplio conocimiento en la formulación de políticas públicas y analistas políticos que comprenden y actúan en el escenario político.
        </p>
      </Container>
      <Container marginTop marginBottom paddingBottom paddingTop borderBottom borderTop>
        <div className="md:flex md:flex-row mx-auto justify-center">
          <div className="mx-auto md:mx-0 md:flex mb-6">
            <img 
              className="w-80 h-80 md:w-96 md:h-96 lg:w-102 lg:h-102 mx-auto my-auto rounded shadow object-cover mx-auto" 
              src="/esttuaReforma.jpg" 
              alt="estatua reforma"
              style={{objectPosition:"50% 0%"}}  
            />
          </div>
          <div className="md:flex md:flex-col justify-center text-gray-500">
            <div className="mx-auto w-96 px-2 md:px-4 lg:px-8">
              <p className="uppercase text-center md:text-left text-2xl md:text-3xl lg:text-4xl mb-4">Creemos en:</p>
              <p className="text-gray-500 text-sm md:text-base lg:text-lg mb-4 md:mb-6 lg:mb-8">
                <span className="font-bold text-teal-600 text-base md:text-xl lg:text-2xl">+ </span> 
                La <span className="text-teal-600 font-semibold">capacidad y potencial</span> de nuestros clientes para detonar mayor actividad económica y crecimiento.
              </p>
              <p className="text-gray-500 text-sm md:text-base lg:text-lg mb-4 md:mb-6 lg:mb-8">
                <span className="font-bold text-teal-600 text-base md:text-xl lg:text-2xl">+ </span> 
                El valor de la <span className="text-teal-600 font-semibold">atención personalizada</span> mediante servicios y seguimiento cercano de acuerdo a sus oportunidades o retos.
              </p>
              <p className="text-gray-500 text-sm md:text-base lg:text-lg mb-4 md:mb-6 lg:mb-8">
                <span className="font-bold text-teal-600 text-base md:text-xl lg:text-2xl">+ </span> 
                Las soluciones <span className="text-teal-600 font-semibold">concretas</span>, efectivas y honestas.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Container paddingTop paddingBottom paddingLeft paddingRight>
        <PageTitle title="Entorno para hacer negocios"/>
        <p className="text-base md:text-lg lg:text-xl text-gray-500 mx-auto mb-1 md:mb-2 lg:mb-4 mt-8 md:mt-10 lg:mt-12">
          <span className="font-bold text-teal-600 text-lg md:text-xl lg:text-2xl">+ </span> 
          Las decisiones legislativas y regulatorias de política pública del gobierno federal y gobiernos locales tienen un amplio impacto en las decisiones financieras, legales y comerciales relacionadas con las actividades principales de las organizaciones.
        </p>
        <p className="text-base md:text-lg lg:text-xl text-gray-500 mx-auto mb-1 md:mb-2 lg:mb-4 mt-8 md:mt-10 lg:mt-12">
          <span className="font-bold text-teal-600 text-lg md:text-xl lg:text-2xl">+ </span> 
          Mantener una ventaja competitiva para anticipar y resolver problemas requiere de una comprensión profunda de la política interior del país, del proceso legislativo y regulatorio, además de un conocimiento de su industria, áreas fundamentales y sus necesidades para crear oportunidades y estrategias de manera proactiva, junto a una relación persuasiva con los tomadores de decisiones en el gobierno.
        </p>
      </Container>
    </>
  );
}

export default AboutPage;