import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const router = useLocation()
  
  const Link1 = () => {
    return (
      <Link to="/">
        <div className={"mx-0 py-1 w-40 md:w-32 lg:w-40 flex flex-col" + (router.pathname === "/" ? " bg-teal-600 text-white" : " text-gray-500 hover:text-gray-600")}>
          <span className="my-auto mx-auto font-medium text-base md:text-lg lg:text-xl">Inicio</span>
        </div>
      </Link>
    );
  }
  const Link2 = () => {
    return (
      <Link to="/about">
        <div className={"mx-0 py-1 w-40 md:w-32 lg:w-40 flex flex-col" + (router.pathname === "/about" ? " bg-teal-600 text-white" : " text-gray-500 hover:text-gray-600")}>
          <span className="my-auto mx-auto font-medium text-base md:text-lg lg:text-xl">Nosotros</span>
        </div>
      </Link>
    );
  }
  const Link3 = () => {
    return (
      <Link to="/services">
        <div className={"mx-0 py-1 w-40 md:w-32 lg:w-40 flex flex-col" + (router.pathname === "/services" ? " bg-teal-600 text-white" : " text-gray-500 hover:text-gray-600")}>
          <span className="my-auto mx-auto font-medium text-base md:text-lg lg:text-xl">Servicios</span>
        </div>
      </Link>
    );
  }

  return (
    <div className="w-full">
      <div className="w-full absolute h-10 bg-teal-600 justify-center md:justify-start px-0 md:px-64 flex flex-row-reverse z-50">
        <a className="h-5 w-5 md:h-6 md:w-6 my-auto mr-auto md:ml-4 md:mr-0" href="https://www.linkedin.com/company/inteligenciamas/" target="_blank" rel="noreferrer">
          <svg className="h-5 w-5 md:h-6 md:w-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
          </svg>
        </a>
        <a className="h-5 w-5 md:h-6 md:w-6 my-auto mx-3 md:ml-4 md:mr-0" href="https://twitter.com/Inteli_Mas" target="_blank" rel="noreferrer">
          <svg className="h-5 w-5 md:h-6 md:w-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
          </svg>
        </a>
        <a className="h-5 w-5 md:h-6 md:w-6 my-auto ml-auto md:ml-4" href="https://www.facebook.com/InteligenciaMas/" target="_blank" rel="noreferrer">
          <svg className="h-5 w-5 md:h-6 md:w-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
          </svg>
        </a>
      </div>      
      <div className="flex flex-col py-4">
        <Link to="/">
          <img className="mx-auto h-24 mt-8 z-0 object-contain" src="/logo/logo2.jpg" alt="logo"/>
        </Link>
      </div>
      <div className="hidden md:block">
        <div className="md:flex ml-auto mr-0">
          <div className="flex flex-row mt-1 mx-auto text-center align-bottom max-w-min">
            <Link1/>
            <Link2/>
            <Link3/>
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <div className="md:flex ml-auto mr-2">
          <div className="flex flex-row mt-1 mx-auto text-center align-bottom max-w-min">
            <Link1/>
            <Link2/>
          </div>
        </div>
        <div className="md:flex ml-2 mr-auto mt-2">
          <div className="flex flex-row mt-1 mx-auto text-center align-bottom max-w-min">
            <Link3/>
          </div>
      </div>
      </div>
    </div>
  );
}

export default Header;