
const Container = ({
    className, 
    paddingTop, paddingBottom, paddingLeft, paddingRight, 
    marginTop, marginBottom, borderTop, borderBottom, children
}) => {

    return (
        <div className={
            "w-full md:w-10/12 lg:w-9/12 mx-auto border-gray-600 " 
            + (borderTop ? "border-t-2" : "") + " "
            + (borderBottom ? "border-b-2" : "") + " "
            + (paddingTop ? "pt-6 md:pt-8 lg:pt-12" : "") + " "
            + (paddingBottom ? "pb-4" : "") + " "
            + (paddingLeft ? "pl-5 md:pl-0" : "") + " "
            + (paddingRight ? "pr-5 md:pr-0" : "") + " "
            + (marginTop ? "mt-6 md:mt-8 lg:mt-12" : "") + " "
            + (marginBottom ? "mb-6 md:mb-8 lg:mb-12": "") + " "
            + (className ? className : "")
        }>
            {children}
        </div>
    );
}

export default Container;