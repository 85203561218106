import { useEffect, useState } from "react";
import Container from "../../components/Container";
import Icon from "../../components/Icon";
import PageTitle from "../../components/Text/PageTitle";
const { GoogleSpreadsheet } = require('google-spreadsheet');
const creds = require('../../store/creds.json')

const NewsPage = () => {

  // Initialize the sheet - doc ID is the long id in the sheets URL
  const doc = new GoogleSpreadsheet('1c90Iy-VzKEE0A_hzb3VcoHxy3EQaLvS_EVwTg0OS_Cw');

  const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL
    ? process.env.REACT_APP_GOOGLE_CLIENT_EMAIL
    : 'int-mas-spread-sheets@disco-idea-322916.iam.gserviceaccount.com'
  // Initialize Auth - see more available options at https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
  
  const [news, setNews] = useState([])
  const [loaded, setLoaded] = useState(false)

  const loadNews = async () => {
    await doc.useServiceAccountAuth(creds, CLIENT_EMAIL)
    await doc.loadInfo()
    const sheet = doc.sheetsById["0"]
    const rows = await sheet.getRows()

    const news = []

    for (var i = 0; i < rows.length; i++) {
      try {
        const name = rows[i]['TITULO']
        const desc = rows[i]['DESCRIPCION']
        const image = rows[i]['URL_IMAGEN']
        const cat = `${rows[i]['CAT']}`.split(',')
        const date = rows[i]['FECHA']
        const readMore = rows[i]['LEER_MAS']
        console.log(readMore)
        news.push({
          name: name,
          desc: desc,
          image: image,
          cat: cat,
          date: date,
          readMore: readMore
        })
      } catch (err) {
        console.error(`ERROR ON ROW ${i}`)
      }
    }
    setNews(news)
    setLoaded(true)
  }

  useEffect(()=>{
    loadNews()
  }, [])

  return (
    <>
      <PageTitle title=""/>
      {(news.length === 0) &&
        <div className="flex flex-col h-4/6 justify-center">
          <div className="flex flex-row my-auto mx-auto justify-center text-center">
            {loaded === false && 
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            }
            <p className="text-gray-500 text-center flex flex-row">
              {loaded === false ? "Cargando publicaciones ..." : "No hay publicaciones aún."}
            </p>
          </div>
        </div>
      }
      {news.length > 0 && news.map((news, index)=>{
        return (
          <Container borderTop={index !== 0 ? true : undefined} paddingTop paddingBottom marginBottom marginTop>
            <div className={`flex flex-row${(index%2!==0) ? '-reverse' : ''}`} id={`news-${index}`}>
              <div className="flex flex-col h-full my-auto justify-center">
                <Icon larger={true} imgSrc={news.image} className="my-auto" alt={`news-${index}`}/>
              </div>
              <div className="flex flex-1 flex-col">
                <div className="flex flex-1 flex-col ml-2 md:ml-4 my-auto pr-4">
                  <p className="uppercase text-lg md:text-xl lg:text-2xl text-gray-500">
                    {news.name}
                  </p>
                  <p className="mb-2 md:mb-4 lg:mb-6">
                    <span className="text-gray-400 text-md mr-2"> {news.date} </span>
                    {news.cat.map(s=>{
                      return (<span className="rounded-full bg-teal-600 text-white text-xs px-2 py-1 mr-2">{s}</span>);
                    })}
                  </p>
                  <p className="text-gray-500 text-sm md:text-base lg:text-lg">
                    <span className="font-bold text-teal-600 text-xl md:text-2xl">+ </span> 
                    {news.desc}
                  </p>
                </div>
                {(news.readMore !== "" && news.readMore !== undefined && news.readMore !== null) &&
                  <a className="cursor-pointer mt-4 ml-2 md:ml-4" src={news.readMore} target="_blank" onClick={()=>window.open(news.readMore, '_blank')}>
                    <span className="rounded bg-teal-600 text-white w-min-content text-lg px-3 py-1">Leer Más</span>
                  </a>
                }
              </div>
            </div>
          </Container>
        );
      })}
    </>
  );
}

export default NewsPage;