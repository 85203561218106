import { HashLink as Link } from 'react-router-hash-link';


const Icon = ({svgSrc, imgSrc, title, selectable, target, className, alt, larger}) => {

    const baseClass = (larger === true ? "h-auto w-40 md:w-44" : "h-auto w-28 md:w-36 ") + " " + " mx-4 md:mx-0 md:h-64 flex flex-col mx-auto md:mx-0"

    const sizeClasses = larger === true ? "w-28 h-28 md:w-32 md:h-32 lg:w-36 lg:h-36" : "w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28"
    
    const renderElement = ()=>{
        return (
            <div className={baseClass + " " + (className ? className : "mb-4 md:mb-0") + " "}>
                <div className={sizeClasses + " " +  " mx-auto rounded-full bg-white border-teal-600 border-4 shadow-md " + (className? className : "mb-4")} style={{overflow: 'hidden'}}>
                    { (imgSrc && svgSrc === undefined) && 
                        <img src={imgSrc} alt={alt ? alt : "servicio-1"} style={{overflow: 'hidden'}}/>
                    }
                    {svgSrc && 
                        <object data={svgSrc} type="image/svg+xml">
                            <img src={imgSrc} alt="servicio-1"/>
                        </object>
                    }
                </div>
                {title && <p className="text-sm md:text-base lg:text-lg text-gray-600 text-center">{title}</p>}
            </div>
        );
    }

    return (
        <>
        {selectable && 
            <Link 
                to={target}
                className={baseClass + " " + (className ? className : "mb-4 md:mb-0") + " "}
            >
                {renderElement()}
            </Link>
        }
        {!selectable && renderElement()}
        </>
    );

}

export default Icon;